/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { GeoPoint } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class TableExportService {

    constructor() { console.log('TableExportService'); }

    static exportToExcel(arg0: any[], arg1: string) {
        throw new Error('Method not implemented.');
    }

    async exportToExcel(data: any[], filename: string): Promise<void> {
        if (data.length === 0) {
            console.warn('No data to export');
            return;
        }

        // Get flattened keys from all objects in the data array
        const allKeys = this.getFlattenedKeys(data);

        // Sort keys to prioritize 'id' fields
        const sortedKeys = this.sortKeys(allKeys);

        // Create worksheet with flattened data
        const flattenedData = data.map(row => this.flattenObject(row));
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            flattenedData.map(row => this.createFullRowObject(row, sortedKeys))
        );

        // Manually add the header row
        XLSX.utils.sheet_add_aoa(ws, [sortedKeys], { origin: 'A1' });

        // Get the range of the worksheet
        const range = XLSX.utils.decode_range(ws['!ref']!);

        // Add autofilter
        ws['!autofilter'] = { ref: XLSX.utils.encode_range(range) };

        // Create workbook and add the worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Convert workbook to array buffer
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        this.downloadFile(blob, filename);
    }

    private getFlattenedKeys(data: any[]): string[] {
        const keySet = new Set<string>();
        data.forEach(item => {
            const flattenedKeys = this.getKeysRecursively(item);
            flattenedKeys.forEach(key => keySet.add(key));
        });
        return Array.from(keySet);
    }

    private getKeysRecursively(obj: any, prefix = ''): string[] {
        const keys: string[] = [];

        if (!obj || typeof obj !== 'object') {
            return keys;
        }

        Object.entries(obj).forEach(([key, value]) => {
            const newKey = prefix ? `${prefix}_${key}` : key;

            if (value instanceof GeoPoint) {
                keys.push(`${newKey}_latitude`);
                keys.push(`${newKey}_longitude`);
            } else if (value && typeof value === 'object' && !(value instanceof Date)) {
                // Recursively get keys for nested objects
                keys.push(...this.getKeysRecursively(value, newKey));
            } else {
                keys.push(newKey);
            }
        });

        return keys;
    }

    private flattenObject(obj: any, prefix = ''): any {
        const flattened: any = {};

        if (!obj || typeof obj !== 'object') {
            return flattened;
        }

        Object.entries(obj).forEach(([key, value]) => {
            const newKey = prefix ? `${prefix}_${key}` : key;

            if (value instanceof GeoPoint) {
                flattened[`${newKey}_latitude`] = value.latitude;
                flattened[`${newKey}_longitude`] = value.longitude;
            } else if (value && typeof value === 'object' && !(value instanceof Date)) {
                Object.assign(flattened, this.flattenObject(value, newKey));
            } else {
                flattened[newKey] = value;
            }
        });

        return flattened;
    }

    private sortKeys(keys: string[]): string[] {
        return keys.sort((a, b) => {
            const aHasId = a.toLowerCase().includes('id');
            const bHasId = b.toLowerCase().includes('id');

            if (aHasId && !bHasId) return -1;
            if (!aHasId && bHasId) return 1;
            if (aHasId && bHasId) {
                // If both have 'id', prioritize the shorter one
                return a.length - b.length;
            }

            // Group related fields together
            const aParts = a.split('_');
            const bParts = b.split('_');

            if (aParts[0] !== bParts[0]) {
                return aParts[0].localeCompare(bParts[0]);
            }

            return a.localeCompare(b);
        });
    }

    private createFullRowObject(row: any, allKeys: string[]): any {
        return allKeys.reduce((obj: any, key: string) => {
            obj[key] = row[key] !== undefined ? this.formatValue(row[key]) : '';
            return obj;
        }, {});
    }

    private formatValue(value: any): any {
        if (value instanceof Date) {
            return value.toISOString();
        }
        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value);
        }
        return value;
    }

    private downloadFile(blob: Blob, filename: string) {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}